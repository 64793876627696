// assets
import { UserOutlined, TeamOutlined, MailOutlined, QuestionCircleOutlined } from '@ant-design/icons';

// icons
const icons = {
  UserOutlined,
  TeamOutlined,
  MailOutlined,
  QuestionCircleOutlined
};

const account = (isInternalDomain) => ({
  id: 'account',
  title: 'My Account',
  align: 'bottom',
  type: 'group',
  security: 1,
  children: isInternalDomain
    ? [
        {
          id: 'support',
          title: 'Support',
          type: 'item',
          security: 1,
          url: '/support',
          icon: icons.QuestionCircleOutlined
        },
        {
          id: 'profile',
          title: 'Profile',
          type: 'item',
          security: 1,
          url: '/profile',
          icon: icons.UserOutlined
        }
      ]
    : [
        {
          id: 'profile',
          title: 'Profile',
          type: 'item',
          security: 1,
          url: '/profile',
          icon: icons.UserOutlined
        }
      ]
});

export default account;
