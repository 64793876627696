/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import useAuth from 'hooks/useAuth';
import useMenuItems from 'menu-items';
// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { user } = useAuth();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const menuItem = useMenuItems();

  const topNavGroupItems = menuItem.filter((item) => item.align === 'top');
  const bottomNavGroupItems = menuItem.filter((item) => item.align === 'bottom');

  const topNavGroups = topNavGroupItems.map((item) => {
    if (!user || !item) return null;

    if (user.security < item.security) {
      return null;
    }

    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  const bottomNavGroups = bottomNavGroupItems.map((item) => {
    if (!user || !item) return null;

    if (user.security < item.security) {
      return null;
    }

    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return (
    <Box
      sx={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        pt: drawerOpen ? 2 : 0,
        '& > ul:first-of-type': { mt: 0 }
      }}
    >
      <Box>{topNavGroups}</Box>
      <Box>{bottomNavGroups}</Box>
    </Box>
  );
};

export default Navigation;
