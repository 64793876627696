export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const JWT_API = {
  secret: process.env.REACT_APP_JWT_SECRET_KEY,
  timeout: process.env.REACT_APP_JWT_EXPIRES_IN
};

// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/dashboard',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;
