import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse }) => {
  const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="ANTLERS" width="100" />
     *
     */
    <>
      <svg height="40" width="200">
        <text
          x="20"
          y="40"
          style={{ fontSize: '36px', fontFamily: 'Roboto', fontWeight: '700' }}
          fill={theme.palette.mode === 'dark' || reverse ? theme.palette.common.white : theme.palette.common.black}
        >
          ANTLERS
        </text>
      </svg>
    </>
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
