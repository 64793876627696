import { lazy } from 'react';

import Loadable from 'components/Loadable';

// maintenance pages
const Error404 = Loadable(lazy(() => import('pages/maintenance/404')));
const Error500 = Loadable(lazy(() => import('pages/maintenance/500')));
const UnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const ComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

const Activate = Loadable(lazy(() => import('pages/link/activate')));
const PasswordReset = Loadable(lazy(() => import('pages/link/password')));

const OtherRoutes = {
  path: '/',
  children: [
    {
      path: 'link/activate/:uid/:token',
      element: <Activate />
    },
    {
      path: 'link/password/:uid/:token',
      element: <PasswordReset />
    },
    {
      path: '404',
      element: <Error404 />
    },
    {
      path: '500',
      element: <Error500 />
    },
    {
      path: 'under-construction',
      element: <UnderConstruction />
    },
    {
      path: 'coming-soon',
      element: <ComingSoon />
    }
  ]
};

export default OtherRoutes;
