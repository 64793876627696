import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'components/route-guard/AuthGuard';

// Dashboard pages
const DashboardPage = Loadable(lazy(() => import('pages/dashboard')));
const ProjectPage = Loadable(lazy(() => import('pages/project')));
const DesignPage = Loadable(lazy(() => import('pages/project/design')));

const SupportPage = Loadable(lazy(() => import('pages/support')));
const ProfilePage = Loadable(lazy(() => import('pages/profile')));

// Admin pages
const UsersPage = Loadable(lazy(() => import('pages/admin/users')));
const OrganizationPage = Loadable(lazy(() => import('pages/organization')));
const BillingPage = Loadable(lazy(() => import('pages/billing')));
const ConfigurationPage = Loadable(lazy(() => import('pages/configuration')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <DashboardPage />
        },
        {
          path: 'project',
          element: <ProjectPage />
        },
        {
          path: 'project/design/:id',
          element: <DesignPage />
        },
        {
          path: 'support',
          element: <SupportPage />
        },
        {
          path: 'profile',
          element: <ProfilePage />
        }
      ]
    },
    {
      path: '/admin',
      element: (
        // NOTE: for development purpose, use AuthGuard once Auth is implemented
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'users',
          element: <UsersPage />
        },
        {
          path: 'organization',
          element: <OrganizationPage />
        },
        {
          path: 'billing',
          element: <BillingPage />
        },
        {
          path: 'configuration',
          element: <ConfigurationPage />
        }
      ]
    }
  ]
};

export default MainRoutes;
