// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// AG Grid styles
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-material.css'; // Optional theme CSS
import './styles/global.css';

// Auth provider
import { AuthProvider } from 'contexts/AuthContext';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <ThemeCustomization>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ScrollTop>
        <AuthProvider>
          <Snackbar />
          <Routes />
        </AuthProvider>
      </ScrollTop>
    </LocalizationProvider>
  </ThemeCustomization>
);

export default App;
