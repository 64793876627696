import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// third-party
import jwtDecode from 'jwt-decode';

// project import
import Loader from 'components/Loader';
import { authApi } from 'api/auth';
import { userApi } from 'api/users';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const refreshToken = globalThis.localStorage.getItem('refreshToken');
        if (verifyToken(refreshToken)) {
          const { data } = await authApi.refresh({ refresh: refreshToken });
          const { access, refresh } = data;
          globalThis.localStorage.setItem('accessToken', access);
          globalThis.localStorage.setItem('refreshToken', refresh);
        }

        const accessToken = globalThis.localStorage.getItem('accessToken');
        if (verifyToken(accessToken)) {
          const response = await userApi.profile();
          const user = response.data;

          setState({ isInitialized: true, isLoggedIn: true, user });
        } else {
          setState({ isInitialized: true, isLoggedIn: false, user: null });
        }
      } catch (err) {
        setState({ isInitialized: true, isLoggedIn: false, user: null });
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (username, password) => {
    const { data, ok } = await authApi.login({ username, password });

    if (!ok) throw new Error(data.detail);
    const { access, refresh } = data;
    globalThis.localStorage.setItem('email', username);
    globalThis.localStorage.setItem('accessToken', access);
    globalThis.localStorage.setItem('refreshToken', refresh);

    const response = await userApi.profile();
    const user = response.data;

    setState({ ...state, isLoggedIn: true, user });
  };

  const register = async (username, password, first_name, last_name) => {
    const { ok } = await authApi.register({
      username,
      password,
      first_name,
      last_name
    });

    if (ok) {
      navigate('/login');
    } else {
      throw new Error('Registration failed');
    }
  };

  const logout = () => {
    authApi.logout().finally(() => {
      setState({ ...state, isLoggedIn: false, user: null });
      globalThis.localStorage.removeItem('email');
      globalThis.localStorage.removeItem('accessToken');
      globalThis.localStorage.removeItem('refreshToken');
    });
  };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node
};

export default AuthContext;
