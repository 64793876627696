// assets
import { UserOutlined, FileTextOutlined, TeamOutlined, DollarCircleOutlined, SettingOutlined } from '@ant-design/icons';

// icons
const icons = {
  UserOutlined,
  FileTextOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  SettingOutlined
};
// ==============================|| MENU ITEMS - ADMIN ||============================== //

const admin = (isInternalDomain) => ({
  id: 'admin',
  title: isInternalDomain ? 'Admin' : '',
  align: 'top',
  type: 'group',
  security: 1,
  children: isInternalDomain
    ? [
        {
          id: 'users',
          title: 'Users',
          type: 'item',
          security: 1,
          url: '/admin/users',
          icon: icons.UserOutlined
        },
        {
          id: 'organization',
          title: 'Organization',
          type: 'item',
          security: 1,
          url: '/admin/organization',
          icon: icons.TeamOutlined
        },
        {
          id: 'billing',
          title: 'Billing',
          type: 'item',
          security: 1,
          url: '/admin/billing',
          icon: icons.DollarCircleOutlined
        },
        {
          id: 'configuration',
          title: 'Configuration',
          type: 'item',
          security: 1,
          url: '/admin/configuration',
          icon: icons.SettingOutlined
        }
      ]
    : [
        // {
        //   id: 'configuration',
        //   title: 'Configuration',
        //   type: 'item',
        //   security: 1,
        //   url: '/admin/configuration',
        //   icon: icons.SettingOutlined
        // }
      ]
});

export default admin;
