import { webApi } from '../config';

class AuthApi {
  login = (body) => webApi().post('/auth/login/', body, { formData: true });
  refresh = (body) => webApi({ auth: true }).post('/auth/refresh/', body, { formData: true });
  logout = () => webApi({ auth: true }).post('/auth/logout/');

  register = (body) => webApi().post('/auth/register/', body);
  registerConfirm = (body) => webApi().post('/auth/register/confirm/', body);
}

export const authApi = new AuthApi();
