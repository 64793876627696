// assets
import {
  DashboardOutlined,
  CarOutlined,
  SwapOutlined,
  CarryOutOutlined,
  HistoryOutlined,
  PullRequestOutlined,
  ShrinkOutlined,
  ExpandAltOutlined,
  ProjectOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  QuestionCircleOutlined,
  ClockCircleOutlined,
  RedoOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  CarOutlined,
  SwapOutlined,
  CarryOutOutlined,
  HistoryOutlined,
  PullRequestOutlined,
  ShrinkOutlined,
  ExpandAltOutlined,
  ProjectOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  QuestionCircleOutlined,
  ClockCircleOutlined,
  RedoOutlined,
  CheckCircleOutlined
};

const internalDomainRoutes = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    security: 1,
    url: '/dashboard',
    icon: icons.DashboardOutlined
  },
  {
    id: 'project',
    title: 'Project',
    type: 'item', // type: 'collapse',
    security: 1,
    url: '/project',
    icon: icons.ProjectOutlined,
    children: [
      {
        id: 'pending',
        title: 'Pending',
        type: 'item',
        security: 1,
        url: '/project/pending',
        icon: icons.ClockCircleOutlined
      },
      {
        id: 'processing',
        title: 'Processing',
        type: 'item',
        security: 1,
        url: '/project/processing',
        icon: icons.RedoOutlined
      },
      {
        id: 'completed',
        title: 'Completed',
        type: 'item',
        security: 1,
        url: '/project/completed',
        icon: icons.CheckCircleOutlined
      }
    ]
  }
];

const otherRoutes = [
  {
    id: 'project',
    title: 'Project',
    type: 'item', // type: 'collapse',
    security: 1,
    url: '/project',
    icon: icons.ProjectOutlined,
    children: [
      {
        id: 'pending',
        title: 'Pending',
        type: 'item',
        security: 1,
        url: '/project/pending',
        icon: icons.ClockCircleOutlined
      },
      {
        id: 'processing',
        title: 'Processing',
        type: 'item',
        security: 1,
        url: '/project/processing',
        icon: icons.RedoOutlined
      },
      {
        id: 'completed',
        title: 'Completed',
        type: 'item',
        security: 1,
        url: '/project/completed',
        icon: icons.CheckCircleOutlined
      }
    ]
  }
];

export { otherRoutes, internalDomainRoutes };
