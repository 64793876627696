import { useRoutes, Navigate } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import OtherRoutes from './OtherRoutes';

const FallbackRoutes = {
  path: '*',
  element: <Navigate to="/" />
};

export default function ThemeRoutes() {
  return useRoutes([LoginRoutes, MainRoutes, OtherRoutes, FallbackRoutes]);
}
