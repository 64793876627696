// project import
import { internalDomainRoutes, otherRoutes } from './dashboard';
import account from './account';
import admin from './admin';
import { useState, useEffect } from 'react';

const useMenuItems = () => {
  const [dashboardRoutes, setDashboardRoutes] = useState([]);
  const [adminRoutes, setAdminRoutes] = useState([]);
  const [accountRoutes, setAccountRoutes] = useState([]);
  useEffect(() => {
    const internalDomain = process.env.REACT_APP_INTERNAL_DOMAIN;
    const email = localStorage.getItem('email');
    const regex = new RegExp(`${internalDomain}$`, 'i');
    const isInternalDomain = regex.test(email);

    const getDashboardRoutes = (isInternalDomain) => ({
      id: 'general',
      title: 'Dashboard',
      align: 'top',
      type: 'group',
      security: 1,
      children: isInternalDomain ? internalDomainRoutes : otherRoutes
    });
    setDashboardRoutes(getDashboardRoutes(isInternalDomain));
    setAdminRoutes(admin(isInternalDomain));
    setAccountRoutes(account(isInternalDomain));
  }, []);

  return [dashboardRoutes, adminRoutes, accountRoutes];
};

export default useMenuItems;
